import { render, staticRenderFns } from "./addgroup-modal.vue?vue&type=template&id=28e08cb4&scoped=true&"
import script from "./addgroup-modal.vue?vue&type=script&lang=ts&"
export * from "./addgroup-modal.vue?vue&type=script&lang=ts&"
import style0 from "./addgroup-modal.vue?vue&type=style&index=0&id=28e08cb4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e08cb4",
  null
  
)

export default component.exports