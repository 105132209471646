



















/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean

  loading = false
  name = ''

  @Watch('isShow')
  getIsShow(val: boolean) {
    if (val) {
      this.name = ''
    }
  }
  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    if (this.name === '') {
      this.$message.warning('请先填写设备组名称')
      return
    }
    this.loading = true
    this.$api.equipment
      .addDeviceGroup({
        name: this.name
      })
      .then((res: any) => {
        this.loading = false
        const data = res.data
        if (data.success) {
          this.$message.success('添加设备组成功')
          this.closeDialog()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.loading = false
        this.$message.error('添加设备组失败')
      })
  }
}
