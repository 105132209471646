













































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import AddgroupModal from './addgroup-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    AddgroupModal
  }
})
export default class EquipList extends Vue {
  $constants: any
  // 字典数据
  // 设备类型
  deviceTypeDicts: any = []
  // 设备状态
  deviceStatusDicts: any = []
  type = ''
  status = ''
  listLoading = false
  tableHeader = [
    {
      key: 'type',
      name: '设备类型',
      width: '12%',
      isSpecial: true
    },
    {
      key: 'model',
      name: '设备型号',
      width: '16%'
    },
    {
      key: 'status',
      name: '设备状态',
      width: '12%',
      isSpecial: true
    },
    {
      key: 'groupName',
      name: '设备组',
      width: '14%'
    },
    {
      key: 'deviceName',
      name: '设备名称',
      width: '14%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '16%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '16%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  openWhichModal = ''
  deviceId = 0
  deviceRemark = ''
  remarkLoading = false

  async beforeCreate() {
    // 获取相关字典
    // 设备类型
    this.deviceTypeDicts = await getDicts('DEVICE_TYPE')
    // 设备状态
    this.deviceStatusDicts = await getDicts('DEVICE_STATUS')
  }

  created() {
    this.getEquipmentList()
  }

  search() {
    this.listQuery = {
      page: 1,
      limit: 15
    }
    this.getEquipmentList()
  }

  // 获取设备列表
  getEquipmentList() {
    this.listLoading = true
    this.$api.equipment
      .getEquipmentList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        type: this.type,
        status: this.status
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 更新设备状态
  updateStatus(id: number, status: number) {
    this.$api.equipment
      .updateDeviceStatus({
        id,
        status: status === 1 ? 2 : 1
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success('更新状态成功')
          this.getEquipmentList()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.$message.error('更新状态失败')
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  // 关闭对话框
  closeDialog() {
    this.openWhichModal = ''
  }

  toAdd() {
    this.$router.push('/equipments/equipment-edit')
  }

  toEdit(id: number) {
    this.$router.push({
      path: '/equipments/equipment-edit',
      query: {
        id: id + ''
      }
    })
  }
}
